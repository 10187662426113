import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Engineer = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="ソフトウェアエンジニア（経験者）募集要項｜Recruit｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-recruitPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">Recruit</h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/recruit#message" className=''>
                社長からのメッセージ
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/recruit#RecruitmentType" className=''>
                募集職種
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/recruit#WorkingWelfare" className=''>
                働き方・福利厚生
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/recruit#FAQ" className=''>
                採用FAQ
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="RecruitmentType" className="mb-20">
              <h2 className="text-xl sm:text-2xl border-b border-key-sand mb-5 py-3 ">
              ソフトウェアエンジニア（経験者）
              </h2>
              <h3 className="text-xl">募集要項</h3>
              <table className="my-4 border border-key-sand">
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">雇用形態</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  正社員
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">勤務地</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  東京都港区赤坂6-4-19 赤坂T.S.C.ビル5階
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">最寄駅</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  東京メトロ千代田線　赤坂駅　徒歩3分<br />
                  東京メトロ銀座線・丸ノ内線　赤坂見附駅　徒歩10分<br />
                  東京メトロ銀座線・南北線　溜池山王駅　徒歩10分
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell">業務内容</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ◆Web系システムの受託開発・運用・保守・改修業務<br />
                  企業様の業務系システム開発について、要件定義、開発、納品、納品後の保守までを一貫して請け負う案件がメインです。<br />
                  まずは、開発メンバーとしてプロジェクトに参加しますが、様々なレイヤの業務に携わることで、最終的には上流工程など、業務改善提案、システム設計が可能なエンジニアにキャリアアップが可能です。<br />
                  環境：PHP、Python3、Golang、JavaScript、TypeScript、Laravel、Vue、その他AWSサービスなど
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">仕事のやりがい</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ・大手サービス企業や調査、教育、医療介護、官公庁など幅広い業界のシステム開発に携わることができます。<br />
                  ・構築するシステムの特性により、新しいテクノロジー（サービス）も含めた構成検討をエンジニアの裁量で提案することができます。それに伴い新技術の検証や性能比較を実施することで、新技術のスキル獲得やシステム設計の幅を広げることができます。<br />
                  ・メンバーは最新技術に敏感で、社内チャットでは技術的な話題で盛り上がったり、技術系の書籍購入・資格取得支援・最新機種の検証機の導入など、エンジニアがスキルアップし続けられる機会が多数あります。
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">エンジニアが働きやすい社風</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ・エンジニアスタッフにはリモートワーク制度が適用され、週1日の出社日以外は在宅勤務することが可能です。<br />
                  ・風通しがよく、声をあげればチャレンジできる環境なので、自身のアイデアを反映できるチャンスが多々あります。様々なことに対して柔軟ですが、その分セルフマネジメントが求められる環境でもあります。<br />
                  ・案件次第ですが、使える技術にあまり大きな制約がないので、新しい技術にも挑戦しやすい環境です。<br />
                  ・少数精鋭で豊富な知識・経験があるスタッフが揃っています。ご入社後は先輩とのOJTを通じて実務をしながら、技術やスキルを磨くことができます。<br />
                  ・全員が高機能オフィスチェアを使用しており、エンジニアが仕事しやすい環境を整えています。<br />
                  ・社員のスキルアップを積極的に応援しています。技術系の書籍購入補助（四半期に上限5,000円まで）、資格取得支援などを行っています。セミナー、勉強会への参加も推奨しており、そのための時間を取りやすい雰囲気があります。
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">応募資格</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  【必須条件】<br />
                  ◆PHP, JavaScript等のプログラミング言語を使用し、開発をされた経験がある方（実務3年以上）<br />
                  ◆プログラミングが好きな方<br />
                  ◆新しい技術に興味関心があり、自ら率先してキャッチアップしチャレンジできる方<br />
                  　<br />
                  【歓迎条件】<br />
                  ◆WEB系システム（フロントエンドまたはバックエンド）の開発・構築経験がある方<br />
                  ◆下記の言語を使用し、開発をされた経験がある方（学校や独学でも可）<br />
                  ※Golang、TypeScript、JavaScript、HTML5・CSS3、laravel、Vue<br />
                  ◆コンテナ技術（Docker等）に関して経験・知識がある方（学校や独学でも可）<br />
                  ◆MySQL, PostgreSQLなどのRDBMS, DWHなどデータベースの運用経験がある方<br />
                  ◆AWS・GCP等のクラウドベンダーを利用したシステム開発の経験がある方（学校や独学でも可）<br />
                  ◆顧客の課題解決のための提案を積極的に行いたい方<br />
                  ◆能動的に行動できる方<br />
                  ◆セルフマネジメントがしっかりできる方
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">学歴</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  学歴不問
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">勤務時間</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  フレックス制　コアタイム11:00～17:00<br />
                  ※入社後1年経過でコアタイム無のフルフレックス制へ変更<br />
                  （所定労働時間 8時間、休憩1時間）<br />
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">給与</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  400～550万円（経験・能力に応じて）<br />
                  <br />
                  ※給与備考<br />
                  月給：266,700円～366,700円<br />
                  （基本給：230,600円～317,100円、固定残業手当：月20時間／36,100円～49,600円）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">残業</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  固定残業時間/月20時間（20時間を超過した場合追加支給）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">賞与</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  年2回（6,12月、計3か月分）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">昇給</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  年1回
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">試用期間</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ６カ月
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">通勤手当</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  通勤交通費支給（上限5万円／月）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">社会保険</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  社会保険完備（雇用、労災、健康、厚生年金）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">喫煙場所</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  敷地内禁煙（屋外喫煙可能場所あり）
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">福利厚生</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  フレックス制度、在宅勤務制度（適用条件あり）、在宅勤務手当、フリードリンク、定期健康診断実施、書籍購入補助（四半期につき上限5,000円）、資格取得支援、インフルエンザ予防接種費用負担、国内/海外社員旅行
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">休日休暇</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  完全週休2日制（土、日、祝）、年末年始・夏季休暇、有給休暇、慶弔休暇、出産・育児・介護休暇
                  </td>
                </tr>

                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">選考プロセス</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  ① 書類選考：書類選考<br />
                  ② 一次選考：面接（役員）※WEB面接可<br />
                  ③ 二次選考：適性検査＋面接（役員）※対面実施<br />
                  ※選考状況によって、面接回数・適性検査の順序が変更となる場合があります。<br />
                  </td>
                </tr>
                <tr className="border-b border-key-sand">
                  <td className="w-full sm:w-40 p-4 bg-key-sand bg-opacity-50 block sm:table-cell ">選考期間</td>
                  <td className="w-full sm:w-auto p-4 block sm:table-cell ">
                  書類選考、面接ともに1週間以内
                  </td>
                </tr>
              </table>

              <Link to="/requirement/entry" class="my-10 flex justify-center bg-[#8CBCB5] px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand shadow">
                <span className="text-2xl text-white font-semibold">応募する</span>
              </Link>


            </section>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default Engineer

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

